<template>
    <div id="download" class="downloads-page">
      <HeadOrders :PathPageFrom="$t('Home')" :PathPageCenter="false" :PathPageTo="$t('DownloadsCenter')" :pathFrom="`/`"/>
      <div class="container">
      <DownloadsCenter />
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'DownloadsCenter Page',
  components: {
    DownloadsCenter: defineAsyncComponent( () => import('@/components/DownloadsCenter/IndexDownloads.vue') ),
    HeadOrders: defineAsyncComponent( () => import('@/components/Global/HeadOrders.vue') ),
  },
  }
  </script>



